<template>
  <b-card>
    <h3>القاعات</h3>
    <hr />
    <!-- {{ hallsSirvice }} -->
    <b-row v-if="loading">
      <b-col cols="3" v-for="n in 8" :key="n">
        <b-skeleton width="100%" height="100px" class="mb-1"></b-skeleton>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col
        v-for="(item, i) in hallsSirvice"
        :key="i"
        cols="3"
      >
        <b-card
        style="min-height: 140px;"
          @click="
          openModal=true;
          id=item.id;
          "
          :class="item.is_available ? '' : 'border'"
          :style="
            item.is_available
              ? 'box-shadow: 0 1px 11px 1px #D0A153 !important'
              : ''
          "
          :bg-variant="item.is_available ? 'primary' : 'light-secondary'"
          class="cursor-pointer text-center"
        >
          <h1
            :class="item.is_available ? 'text-weight' : ''"
            class="text-center"
          >
            {{ item.code }}
          </h1>

          <div class="d-flex justify-content-center flex-column">
        
            <b-badge
            v-if="!item.is_available"
            class="mt-50"
            variant="light-primary"
          >
            <span class="text-primary"
              >القاعة غير مشغلة حالياً</span
            ></b-badge
          >
          <b-badge v-else class="mt-50 text-weight" variant="primary"
            >{{item.title}}</b-badge
          >
          <b-badge
          v-if="item.is_available"
            class="mt-50"
            :variant="
              item.is_available ? 'light-secondary bg-white' : 'primary '
            "
          >
            {{ !item.is_available ? "" : "وقت الإنتهاء : 2:00" }}
          </b-badge>

        </div>
        </b-card>
      </b-col>
    </b-row>
    <modal-detils-halls :id="id" v-model="openModal" ></modal-detils-halls>

  </b-card>
</template>
<script>
import { BCard, BCol, BRow, BSkeleton, BBadge } from "bootstrap-vue";
import { mapActions, mapGetters  } from "vuex";
import ModalDetilsHalls from "../../components/ModalDetilsHalls.vue";
export default {
  components: {
    BCard,
    BCol,
    BRow,
    BSkeleton,
    BBadge,
    ModalDetilsHalls
},
  data: () => {
    return {
      openModal:false,
      id:null,
    };
  },

  methods: {
    ...mapActions("manageCenter/hallsServices", [
      "getHallsSirvice",
      "getHallDetails",
    ]),
  },
  computed: {
    ...mapGetters("manageCenter/hallsServices", ["hallsSirvice", "loading"]),
  },
  created() {
    this.getHallsSirvice();
  },
};
</script>