<template>
    <b-modal id="modal-lg" v-model="openModal" footer-class="removed" centered size="lg" title="جدول القاعات">
        <data-table striped responsive class="mb-0" :fields="fields" :pagination="false" :ep="`occupancy-appointments/${id}`">
            <template #cell(module)>
                <div>ورشة العمل التنموية الخاصة</div>
            </template>
        </data-table>
        <div class="d-flex justify-content-end">
            <b-button variant="primary" @click="$router.push({ name: 'halls-occupancy' })">
                إشغال قاعة
            </b-button>
        </div>
    </b-modal>
</template>
 
<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import DataTable from "@/components/data-table/index.vue";
export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    DataTable,
  },
  props: ["id"],
  data: () => {
    return {
      fields: [
        { key: "module", label: "اسم النشاط",sortable:true },
        { key: "from_time", label: "من" },
        { key: "to_time", label: "إلى" },
      ],
    };
  },
  methods: {
    ...mapActions("manageCenter/hallsServices", [""]),
  },
  watch: {
    openModal(val) {
      if (val) {
      }
    },
  },
  computed: {
    openModal: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.$attrs.value;
      },
    },
    ...mapGetters("manageCenter/hallsServices", ["hallDetails", "loading"]),
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.removed {
  display: none;
}
</style>
  